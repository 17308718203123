exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acceptable-use-js": () => import("./../../../src/pages/acceptable-use.js" /* webpackChunkName: "component---src-pages-acceptable-use-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-old-js": () => import("./../../../src/pages/contact-old.js" /* webpackChunkName: "component---src-pages-contact-old-js" */),
  "component---src-pages-editorials-js": () => import("./../../../src/pages/editorials.js" /* webpackChunkName: "component---src-pages-editorials-js" */),
  "component---src-pages-form-submitted-js": () => import("./../../../src/pages/form-submitted.js" /* webpackChunkName: "component---src-pages-form-submitted-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-modern-slavery-statement-js": () => import("./../../../src/pages/modern-slavery-statement.js" /* webpackChunkName: "component---src-pages-modern-slavery-statement-js" */),
  "component---src-pages-our-podcasts-js": () => import("./../../../src/pages/our-podcasts.js" /* webpackChunkName: "component---src-pages-our-podcasts-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-what-we-do-js": () => import("./../../../src/pages/what-we-do.js" /* webpackChunkName: "component---src-pages-what-we-do-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-editorial-js": () => import("./../../../src/templates/editorial.js" /* webpackChunkName: "component---src-templates-editorial-js" */),
  "component---src-templates-generic-page-js": () => import("./../../../src/templates/generic-page.js" /* webpackChunkName: "component---src-templates-generic-page-js" */),
  "component---src-templates-podcast-js": () => import("./../../../src/templates/podcast.js" /* webpackChunkName: "component---src-templates-podcast-js" */)
}

